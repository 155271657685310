// USA
export const locale = {
  lang: "en",
  data: {
    COMMON: {
      CANCEL: "Cancel",
      SUBMIT: "Submit",
      NO: "No",
      YES: "Yes",
      YES_CLOSE_AND_LOSE_CHANGES: "Yes, close and lose changes",
      CLOSE_AND_DISCARD: "Close and Discard",
      SAVE_UPDATED_DETAILS: "Save Updated Details",
      VALIDATION: {
        SOMETHING_WENT_WRONG: "Oops looks like something went wrong! Please try again.",
        SUCCESSFULLY_UPDATED: "Successfully Updated",
        ANY_CHANGES_MADE_WILL_BE_LOST: 'Any changes made will be lost.',
        ARE_YOU_SURE_YOU_WANT_TO_PROCEED: "Are you sure you want to proceed?"
      },
    },
    NAVBAR: {
      HELLO: "Hello",
      MY_DETAILS: "My Details",
      MY_CONTACT_METHODS: "My Contact Methods",
      MY_DATA_CONSENTS: "My Data Consents",
      LOGOUT: "Logout",
      SELECT_LANGUAGE: "Select Language",
      MENU: "Menu",
      CLOSE: "Close",
      SETTINGS: "Settings",
      TERMSANDCONDITIONS: "Terms & Conditions",
      LANGUAGE: {
        ENLABEL: "English",
        CHLABEL: "Chinese",
        ESLABEL: "Spanish",
        JALABEL: "Japanese",
        DELABEL: "German",
        FRLABEL: "French",
      },
      MENU_ITEM: {
        CURRENT_PLACEMENT: "My Current Placement",
        COMPLIANCE: "My Compliance",
        TIMESHEETS: "My Timesheets",
        EXPENSES: "My Expenses",
      },
    },
    AUTH: {
      LOGIN: {
        BUTTON: {
          SIGN_IN: "Sign In",
        },
        LABEL: {
          EMAIL: "Email",
          PASSWORD: "Password",
          FORGOT_PASSWORD: "Forgot Password?",
        },
        VALIDATION: {
          EMAIL_INVALID: "Invalid Email address",
          EMAIL_MAX_LENGTH: "Email must not exceed {{value}} characters long",
          EMAIL_REQUIRED: "Email is required",
          LOGIN_FAILED: 'The login details are incorrect',
          PASSWORD_MAX_LENGTH: "Password must not exceed {{value}} characters long",
          PASSWORD_MIN_LENGTH: "Password must be at least {{value}} characters long",
          PASSWORD_REQUIRED: "Password is required",
        }
      },
      FORGOT_PASSWORD: {
        ENTER_YOUR_EMAIL_TO_RESET_YOUR_PASSWORD: "Enter your email to reset your password",
        FORGOTTEN_PASSWORD: "Forgotten Password?",
        BUTTON: {
          OK_GOT_IT: "Ok, got it!",
        },
        LABEL: {
          EMAIL: "Email",
          EMAIL_SENT_MESSAGE1: "We've sent an email to the address you specified to reset your password.",
          EMAIL_SENT_MESSAGE2: "If you didn't receive it, please check your junk folder and also verify that the email address entered was correct.",
          EMAIL_SENT_TITLE: "Recovery Email Sent!",
          SUCCESS: "Your account has been successfully reset.",
        },
        VALIDATION: {
          EMAIL_INVALID: "Invalid Email address",
          EMAIL_MAX_LENGTH: "Email must not exceed {{value}} characters long",
          EMAIL_REQUIRED: "Email is required",
        }
      },
      RESET_PASSWORD: {
        ENTER_NEW_PASSWORD_DETAILS: "Enter new password details",
        RESET_PASSWORD: "Reset Password",
        BUTTON: {

        },
        LABEL: {
          CONFIRM_NEW_PASSWORD: "Confirm new password",
          CREATE_NEW_PASSWORD: "Create new password",
          OLD_PASSWORD: "Old Password",
          PASSWORD: "Password",
        },
        VALIDATION: {
          PASSWORDS_DONT_MATCH: "'Passsword' and 'Confirm Password' didn't match",
          PASSWORD_MAX_LENGTH: "Password must not exceed {{value}} characters long",
          PASSWORD_MIN_LENGTH: "Password must be at least {{value}} characters long",
          PASSWORD_PATTERN: "Must contain both uppercase & lowercase letters, a number and one of: -+_!@#$%^&*.,?",
          PASSWORD_REQUIRED: "Password is required",
          REGISTRATION_DETAILS_INCORRECT: "The registration details are incorrect"
        }
      }
    },
    MY_CONTACT_METHODS: {
      MY_CONTACT_METHODS: "My Contact Methods",
      CONTACT_PREFERENCES: "Contact Preferences",
      PLEASE_SELECT_HOW_YOU_WISH_TO_BE_CONTACTED: "Please select how you wish to be contacted.",
      BUTTON: {
      },
      LABEL: {
        RECEIVE: "Receive",
        DO_NOT_RECEIVE: "Do not receive",
        SALES_EMAIL: "Sales Email",
        EMAIL: "Email",
        PHONE_CALL: "Phone Call",
        TEXT_MESSAGE: "Text Message"
      },
      VALIDATION: {
        ERROR_UPDATING: "There was an error updating Contact Preferences"
      }
    },
    MY_DATA_CONSENTS: {
      MY_DATA_CONSENTS: "My Data Consents",
      PLEASE_SELECT_HOW_YOU_WISH_TO_MANAGE_YOUR_DATA_CONSENTS: "Please select how you wish to manage your data consents.",
      BUTTON: {
      },
      LABEL: {
        CONSENT: "Consent",
        WITHDRAW: "Withdraw",
        DATA_CONSENT_PURPOSE: "Data Consent Purpose",
        CONSENT_METHOD: "Consent Method",
        CONSENT_GIVEN: "Consent Given",
        CONSENT_EXPIRY: "Consent Expiry",
        WITHDRAW_CONSENT: "Withdraw Consent"
      },
      VALIDATION: {
        ERROR_UPDATING: "There was an error updating My Data Consents"
      }
    },
    MY_DETAILS:{
      MY_DETAILS: "My Details",
      TAB_TITLES:{
        CANDIDATE_INFORMATION: "Candidate Information",
        PREFERENCES: "Preferences",
        PROFILE: "Profile"
      },
      CANDIDATE_INFORMATION_TAB:{
        FIRST_NAME: "First Name",
        LAST_NAME: "Last Name",
        MOBILE_PHONE: "Mobile Phone",
        HOME_PHONE: "Home Phone",
        WORK_PHONE: "Work Phone",
        PERSONAL_EMAIL: "Personal Email",
        WORK_EMAIL: "Work Email",
        LINKEDIN: "LinkedIn",
        ADDRESSLINE1: "Address Line 1",
        CITY_TOWN: "City/Town",
        POSTCODE: "Postcode",
        COUNTRY: "Country"
      },
      PREFERENCES_TAB:{
        AVAILABILITY: "Availability",
        NOTICE_PERIOD:"Notice Period",
        CURRENCY: "Currency",
        CONTRACTOR: "Contractor",
        TEMP: "Temp",
        RATE_FROM: "Rate From",
        PAY_FREQUENCY: "Pay Frequency",
        RATE_TO: "Rate To",
        PERMANENT: "Permanent",
        SALARY_FROM: "Salary From",
        SALARY_TO: "Salary To",
        CHOOSE_INTERVAL: "Choose Interval...",
        SELECT_A_CURRENCY: "Select a currency...",
        PAY_FREQUENCY_PLACEHOLDER: "Select a pay frequency..."

      },
      PROFILE_TAB:{
        CANDIDATE_SUMMARY: "Candidate Summary",
        ROLES_SOUGHT:"Role Sought",
        CANDIDATE_SUMMARY_PLACEHOLDER: "Please enter a candidate summary...",
        ROLESSOUGHT_SUMMARY_PLACEHOLDER: "Please enter a candidate role sought..."
      },
      VALIDATION: {
        ERROR_UPDATING: "There was an error updating your details",
        FIRST_NAME_VALIDATION: "First name is a required field",
        LAST_NAME_VALIDATION: "Last name is a required field",
        PHONE_NUMBER_VALIDATION: "Please enter a valid phone number",
        PHONE_NUMBER_REQUIRED: "Mobile phone is required",
        EMAIL_VALIDATION: "Please enter a valid email address",
        EMAIL_REQUIRED: "Personal Email is required",
        FREQUENCY_REQUIRED: "Frequency is required",
        RATE_FROM_VALIDATION: "Please ensure 'Rate to' is greater than or equal to 'Rate from'",
        CANDIDATE_SUMMARY_VALIDATION: "Maximum characters allowed is 5,000",
        ROLE_SOUGHT_VALIDATION: "Maximum characters allowed is 2,000",
        SALARY_TO_VALIDATION: "Please ensure 'Salary to' is greater than or equal to 'Salary from'",
        SALARY_REQUIRED: "Salary required",
        PAY_FREQUENCY_REQUIRED: "Pay frequency required",
        SELECT_A_UNIT: "Please select unit",
        RATE_SET_REQUIRED: "Required if rate set",
        SALARY_SET_REQUIRED: "Required if salary set",
        NOTICE_PERIOD_VALIDATION: "The notice period must not be less than 0"
      }
    }
  }
};
