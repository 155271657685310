// China
export const locale = {
  lang: "ch",
  "data": {
    "COMMON": {
      "CANCEL": "取消",
      "SUBMIT": "提交",
      "NO": "否",
      "YES": "是",
      "YES_CLOSE_AND_LOSE_CHANGES": "是，关闭并丢失更改",
      "CLOSE_AND_DISCARD": "关闭并放弃",
      "SAVE_UPDATED_DETAILS": "保存更新的详细信息",
      "VALIDATION": {
        "SOMETHING_WENT_WRONG": "哎呀，好像出了点问题！请再试一次。",
        "SUCCESSFULLY_UPDATED": "更新成功",
        "ANY_CHANGES_MADE_WILL_BE_LOST": "所有更改将会丢失。",
        "ARE_YOU_SURE_YOU_WANT_TO_PROCEED": "你确定要继续吗？"
      }
    },
    "NAVBAR": {
      "HELLO": "你好",
      "MY_DETAILS": "我的详情",
      "MY_CONTACT_METHODS": "我的联系方式",
      "MY_DATA_CONSENTS": "我的数据同意书",
      "LOGOUT": "登出",
      "SELECT_LANGUAGE": "选择语言",
      "MENU": "菜单",
      "CLOSE": "关闭",
      "SETTINGS": "设置",
      "TERMSANDCONDITIONS": "条款和条件",
      "LANGUAGE": {
        "ENLABEL": "英语",
        "CHLABEL": "中文",
        "ESLABEL": "西班牙语",
        "JALABEL": "日语",
        "DELABEL": "德语",
        "FRLABEL": "法语"
      },
      "MENU_ITEM": {
        "CURRENT_PLACEMENT": "我的当前职位",
        "COMPLIANCE": "我的合规性",
        "TIMESHEETS": "我的时间表",
        "EXPENSES": "我的费用"
      }
    },
    "AUTH": {
      "LOGIN": {
        "BUTTON": {
          "SIGN_IN": "登录"
        },
        "LABEL": {
          "EMAIL": "电子邮件",
          "PASSWORD": "密码",
          "FORGOT_PASSWORD": "忘记密码？"
        },
        "VALIDATION": {
          "EMAIL_INVALID": "无效的电子邮件地址",
          "EMAIL_MAX_LENGTH": "电子邮件长度不得超过{{value}}个字符",
          "EMAIL_REQUIRED": "必须输入电子邮件",
          "LOGIN_FAILED": "登录详情不正确",
          "PASSWORD_MAX_LENGTH": "密码长度不得超过{{value}}个字符",
          "PASSWORD_MIN_LENGTH": "密码长度不得少于{{value}}个字符",
          "PASSWORD_REQUIRED": "必须输入密码"
        }
      },
      "FORGOT_PASSWORD": {
        "ENTER_YOUR_EMAIL_TO_RESET_YOUR_PASSWORD": "输入您的电子邮件以重置密码",
        "FORGOTTEN_PASSWORD": "忘记密码？",
        "BUTTON": {
          "OK_GOT_IT": "好的，明白了！"
        },
        "LABEL": {
          "EMAIL": "电子邮件",
          "EMAIL_SENT_MESSAGE1": "我们已将电子邮件发送到您指定的地址以重置密码。",
          "EMAIL_SENT_MESSAGE2": "如果您没有收到，请检查您的垃圾邮件文件夹，并确认输入的电子邮件地址是否正确。",
          "EMAIL_SENT_TITLE": "恢复邮件已发送！",
          "SUCCESS": "您的帐户已成功重置。"
        },
        "VALIDATION": {
          "EMAIL_INVALID": "无效的电子邮件地址",
          "EMAIL_MAX_LENGTH": "电子邮件长度不得超过{{value}}个字符",
          "EMAIL_REQUIRED": "必须输入电子邮件"
        }
      },
      "RESET_PASSWORD": {
        "ENTER_NEW_PASSWORD_DETAILS": "输入新密码详情",
        "RESET_PASSWORD": "重置密码",
        "BUTTON": {},
        "LABEL": {
          "CONFIRM_NEW_PASSWORD": "确认新密码",
          "CREATE_NEW_PASSWORD": "创建新密码",
          "OLD_PASSWORD": "旧密码",
          "PASSWORD": "密码"
        },
        "VALIDATION": {
          "PASSWORDS_DONT_MATCH": "'密码'和'确认密码'不匹配",
          "PASSWORD_MAX_LENGTH": "密码长度不得超过{{value}}个字符",
          "PASSWORD_MIN_LENGTH": "密码长度不得少于{{value}}个字符",
          "PASSWORD_PATTERN": "必须包含大写和小写字母、一个数字以及以下字符之一：-+_!@#$%^&*.,?",
          "PASSWORD_REQUIRED": "必须输入密码",
          "REGISTRATION_DETAILS_INCORRECT": "注册详情不正确"
        }
      }
    },
    "MY_CONTACT_METHODS": {
      "MY_CONTACT_METHODS": "我的联系方式",
      "CONTACT_PREFERENCES": "联系偏好",
      "PLEASE_SELECT_HOW_YOU_WISH_TO_BE_CONTACTED": "请选择您希望被联系的方式。",
      "BUTTON": {},
      "LABEL": {
        "RECEIVE": "接收",
        "DO_NOT_RECEIVE": "不接收",
        "SALES_EMAIL": "销售电子邮件",
        "EMAIL": "电子邮件",
        "PHONE_CALL": "电话",
        "TEXT_MESSAGE": "短信"
      },
      "VALIDATION": {
        "ERROR_UPDATING": "更新联系偏好时出错"
      }
    },
    "MY_DETAILS": {
    "MY_DETAILS": "我的详情",
    "TAB_TITLES": {
      "CANDIDATE_INFORMATION": "候选人信息",
      "PREFERENCES": "偏好设置",
      "PROFILE": "个人资料"
    },
    "CANDIDATE_INFORMATION_TAB": {
      "FIRST_NAME": "名字",
      "LAST_NAME": "姓氏",
      "MOBILE_PHONE": "移动电话",
      "HOME_PHONE": "家庭电话",
      "WORK_PHONE": "工作电话",
      "PERSONAL_EMAIL": "个人邮箱",
      "WORK_EMAIL": "工作邮箱",
      "LINKEDIN": "领英",
      "ADDRESSLINE1": "地址行1",
      "CITY_TOWN": "城市/镇",
      "POSTCODE": "邮政编码",
      "COUNTRY": "国家"
    },
    "PREFERENCES_TAB": {
      "AVAILABILITY": "可用性",
      "NOTICE_PERIOD": "通知期",
      "CURRENCY": "货币",
      "CONTRACTOR": "承包商",
      "TEMP": "临时",
      "RATE_FROM": "费率起始",
      "PAY_FREQUENCY": "支付频率",
      "RATE_TO": "费率至",
      "PERMANENT": "永久",
      "SALARY_FROM": "薪资起始",
      "SALARY_TO": "薪资至",
      "CHOOSE_INTERVAL": "选择间隔...",
      "SELECT_A_CURRENCY": "选择货币...",
      "PAY_FREQUENCY_PLACEHOLDER": "选择付款频率...",
    },
    "PROFILE_TAB": {
      "CANDIDATE_SUMMARY": "候选人概述",
      "ROLES_SOUGHT": "期望职位",
      "CANDIDATE_SUMMARY_PLACEHOLDER": "请输入候选人概要...",
      "ROLESSOUGHT_SUMMARY_PLACEHOLDER": "请输入候选人所寻求的角色概要..."
    },
    "VALIDATION": {
    "ERROR_UPDATING": "更新您的详细信息时出错",
    "FIRST_NAME_VALIDATION": "名字是必填字段",
    "LAST_NAME_VALIDATION": "姓氏是必填字段",
    "PHONE_NUMBER_VALIDATION": "请输入有效的电话号码",
    "PHONE_NUMBER_REQUIRED": "需要移动电话",
    "EMAIL_VALIDATION": "请输入有效的电子邮件地址",
    "EMAIL_REQUIRED": "个人电子邮件是必填项",
    "FREQUENCY_REQUIRED": "频率是必填项",
    "RATE_FROM_VALIDATION": "请确保'最高费率'大于或等于'最低费率'",
    "CANDIDATE_SUMMARY_VALIDATION": "允许的最大字符数为5000",
    "ROLE_SOUGHT_VALIDATION": "允许的最大字符数为2000",
    "SALARY_TO_VALIDATION": "请确保'最高工资'大于或等于'最低工资'",
    "SALARY_REQUIRED": "需要填写薪资",
    "PAY_FREQUENCY_REQUIRED": "需要填写支付频率",
    "SELECT_A_UNIT": "请选择单位",
    "RATE_SET_REQUIRED": "如果设置了费率则必填",
    "SALARY_SET_REQUIRED": "如果设置了工资则必填",
    "NOTICE_PERIOD_VALIDATION": "通知期限不能小于0"
  }
    },
    "MY_DATA_CONSENTS": {
      "MY_DATA_CONSENTS": "我的数据同意",
      "PLEASE_SELECT_HOW_YOU_WISH_TO_MANAGE_YOUR_DATA_CONSENTS": "请选择您希望如何管理您的数据同意。",
      "BUTTON": {},
      "LABEL": {
        "CONSENT": "同意",
        "WITHDRAW": "撤回",
        "DATA_CONSENT_PURPOSE": "数据同意用途",
        "CONSENT_METHOD": "同意方法",
        "CONSENT_GIVEN": "已授予同意",
        "CONSENT_EXPIRY": "同意到期",
        "WITHDRAW_CONSENT": "撤回同意"
      },
      "VALIDATION": {
        "ERROR_UPDATING": "更新我的数据同意时出错"
      }
  }
  }
};
